.loading-tools {
    z-index: 20;
    position: absolute;
    top: 0;
    left:-5px;
    width: 100%;
    background-color: rgba(0,0,0,0.4);
}
.loading-content-tools {
    position: absolute;
    border: 16px solid #f3f3f3; /* Light grey */
    border-top: 16px solid #AFBD20; /* Blue */
    border-radius: 50%;
    width: 50px;
    height: 50px;
    top: 40%;
    left:35%;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
